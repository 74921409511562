import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import ClinicsDetails from "views/clinicDetails"
import PageHeader, { PageHeaderProps } from "components/sections/pageHeader"

const ClinicTemplate = ({ clinic, sections }) =>
  sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.Subheader:
        return <PageHeader {...section} headline={clinic.name} key={i} />

      case SECTIONS.TemplateDetailsSection:
        return (
          <ClinicsDetails
            clinic={{
              ...clinic,
              enableDirectionsCTA:
                section.additionalSettings?.enableDirectionsCTA,
            }}
            key={i}
          />
        )

      default:
        return null
    }
  })

const TemplateSectionProps = {
  __typename: PropTypes.string,
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
  }),
  title: PropTypes.string,
}

export const ClinicTemplateDetailsProps = ClinicsDetails.props

ClinicTemplate.props = {
  model: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(PageHeaderProps),
      PropTypes.shape(TemplateSectionProps),
    ])
  ),
  slugPrefix: PropTypes.string,
  title: PropTypes.string,
}

ClinicTemplate.propTypes = {
  ...ClinicTemplate.props,
  clinic: PropTypes.shape(ClinicsDetails.props),
}

export default ClinicTemplate
