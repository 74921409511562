import React from "react"
import PropTypes from "prop-types"
import Link from "components/elements/link"
// import CallToAction from "components/blocks/callToAction"
import Pin from "../../../../static/images/MapPin.png"
import Phone from "../../../../static/images/Phone.png"
import Fax from "../../../../static/images/Printer.png"
import Clock from "../../../../static/images/Clock.png"
import RichText from "components/elements/richText"
import "./clinicCard.scss"

const ClinicCard = ({
  address: { lat, lon },
  cta,
  detailedAddress,
  enableDirectionsCTA,
  fax,
  phone,
  sidebarDetails,
}) => (
  <div id="ClinicCard">
    <div className="container">
      {detailedAddress && (
        <>
          <div className="clinic-card-details">
            <div className="views-lable">
              <img alt="pin" src={Pin} />
            </div>
            <p
              className="text text-left p-0 m-h d-flex align-items-start"
              title={detailedAddress}
            >
              {detailedAddress.length > 80
                ? `${detailedAddress.slice(0, 80)}...`
                : detailedAddress}
            </p>
          </div>
        </>
      )}
      {enableDirectionsCTA && (
        <div className="links-wrapper">
          <Link
            className="link custome d-inline-block"
            to={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
          >
            Get Directions
          </Link>
        </div>
      )}
      {phone && (
        <div className="phone clinic-card-details">
          <div className="views-lable">
            <img alt="phone" src={Phone} />{" "}
          </div>
          <a className="link custome d-inline-block" href={`tel:${phone}`}>
            {phone}
          </a>
        </div>
      )}
      {fax && (
        <div className="fax clinic-card-details">
          <div className="views-lable">
            <img alt="fax" src={Fax} />
          </div>
          <p className="custome">{fax}</p>
        </div>
      )}
      {sidebarDetails && (
        <div className="center-hours">
          {" "}
          <div className="views-lable">
            <img alt="clock" src={Clock} />
          </div>
          <RichText
            className="sidebarDetails"
            richText={sidebarDetails}
            textStyle="full"
          />{" "}
        </div>
      )}

      {/* {cta && (
        <CallToAction
          className="primaryButton shadow py-2 px-3"
          ctaWrapper="cta"
          data={cta}
          type="button"
        />
      )} */}
    </div>
  </div>
)

export const ClinicCardProps = {
  address: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  detailedAddress: PropTypes.string,
  fax: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  slug: PropTypes.string,
}

ClinicCard.propTypes = {
  ...ClinicCardProps,
  enableDirectionsCTA: PropTypes.bool,
}

export default ClinicCard
