import React, { useState } from "react"
import PropTypes from "prop-types"
import leftCaret from "../../../../static/images/CaretLeft.png"
import rightCaret from "../../../../static/images/CaretRight.png"
import "./imageCarousel.scss"

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const getDisplayImage = offset => {
    return images[(currentIndex + offset) % images.length]
  }
  const handlePreviousClick = () => {
    setCurrentIndex((currentIndex + 1) % images.length)
  }

  const handleNextClick = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1)
  }

  return (
    <div className="carousel-container">
      <div className="carousel-slide">
        <button className="arrow" onClick={handlePreviousClick}>
          <img alt="Previous" src={leftCaret} />
        </button>
        {[0, 1, 2].map(offset => (
          <div className="carousel-image" key={offset}>
            <img
              alt={`${offset + 1}`}
              src={getDisplayImage(offset).fluid.src}
            />
          </div>
        ))}
        <button className="arrow" onClick={handleNextClick}>
          <img alt="Next" src={rightCaret} />
        </button>
      </div>
    </div>
  )
}
ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export default ImageCarousel
