import React from "react"
import PropTypes from "prop-types"
import { CallToActionProps } from "components/blocks/callToAction"
import RichText from "components/elements/richText"
import ClinicCard from "components/blocks/clinicCard"
import ImageCarousel from "components/blocks/imageCarousel"
import Wrapper from "components/sections/wrapper"
import Map from "../../components/elements/map/index"
import { googleMapURL } from "../../utils/constants"
import "./clinicDetails.scss"

const ClinicsDetails = ({ clinic }) => {
  console.log(clinic)
  return (
    <div className="Clinics">
      <div className="clinics-img-containers">
        {clinic.gallery && clinic.gallery.length > 0 ? (
          <ImageCarousel images={clinic.gallery} />
        ) : (
          clinic.image && (
            <div className="clinic-img">
              <img alt="clinic" src={clinic.image.file.url} />
            </div>
          )
        )}
      </div>

      <div className="Clinics" id="content">
        <div>
          {" "}
          <div className="clinic-headline">
            {clinic.headline && (
              <RichText
                className="headline"
                richText={clinic.headline}
                textStyle="full"
              />
            )}
          </div>
        </div>
        <div className="clinic-info">
          <ClinicCard {...clinic} sidebarDetails={clinic.sidebarDetails} />
          <div className="clinic-map">
            <Map
              containerElement={
                <div style={{ height: `368px`, width: `100%` }} />
              }
              googleMapURL={googleMapURL}
              isMarkerShown={true}
              loadingElement={<div style={{ height: `100%` }} />}
              mapElement={
                <div style={{ height: `100%`, borderRadius: `6px` }} />
              }
              markerPosition={[
                {
                  info: clinic.detailedAddress,
                  lat: clinic.address.lat,
                  lon: clinic.address.lon,
                },
              ]}
              zoomChange={() => {}}
            />
          </div>
        </div>
        <div className="container clinic-insurance">
          {clinic.insurances && (
            <RichText
              className="headline insurances"
              richText={clinic.insurances}
              textStyle="full"
            />
          )}
        </div>
        <div className="location-wrapper-sections">
          <Wrapper wrapperSections={clinic.sections} />
        </div>
      </div>
    </div>
  )
}

ClinicsDetails.props = {
  address: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  cta: PropTypes.shape(CallToActionProps),
  detailedAddress: PropTypes.string,
  doctors: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.string,
      details: PropTypes.shape({
        json: PropTypes.object,
      }),
      name: PropTypes.string,
      picture: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }),
        title: PropTypes.string,
      }),
      role: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  fax: PropTypes.string,
  headline: PropTypes.shape({
    json: PropTypes.object,
  }),
  id: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  name: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  sidebarDetails: PropTypes.shape({
    json: PropTypes.object,
  }),
  slug: PropTypes.string,
}

ClinicsDetails.propTypes = {
  clinic: PropTypes.shape(ClinicsDetails.props),
}

export default ClinicsDetails
