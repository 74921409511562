import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template, { ClinicTemplateDetailsProps } from "./template"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { clinic, footer, header, template } }) => {
  // Organization
  const schema = {
    "@context": "https://schema.org",
    "@id": siteUrl,
    "@type": "LocalBusiness",
    address: {
      "@type": "PostalAddress",
      addressCountry: "US",
      addressLocality: "California",
      addressRegion: "CA",
      postalCode: clinic.postalCode,
      streetAddress: clinic.detailedAddress,
    },
    faxNumber: clinic.fax,
    geo: {
      "@type": "GeoCoordinates",
      latitude: clinic.address?.lat,
      longitude: clinic.address?.lon,
    },
    image: clinic.image?.file?.url,
    name: clinic.meta?.title,
    priceRange: "$$$",
    telephone: clinic.phone,
    url: `${siteUrl}/locations/${clinic.slug}`,
  }

  return (
    <>
      <Seo
        {...clinic.meta}
        schemaMarkup={schema}
        slug={`${template.slugPrefix}/${clinic.slug}`}
      />
      <Header {...header} />
      <Template {...template} clinic={clinic} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...ClinicTemplateDetailsProps,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    clinic: PropTypes.shape(TemplateContainer.props),
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(Template.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query Clinic($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    clinic: contentfulClinic(slug: { eq: $slug }) {
      detailedAddress
      fax
      id
      name
      phone
      postalCode
      slug
      sections {
        __typename
        ... on ContentfulSectionSplitContent {
          __typename
          backgroundColor
          reverse
          image {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionFaq {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          questions {
            title: question
            text: answer {
              json
            }
          }
        }
        ... on ContentfulSectionDoctors {
          __typename
          title
          doctors {
            department
            name
            role
            slug
            details {
              json
            }
            picture {
              fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
                src
              }
            }
          }
        }
      }
      address {
        lat
        lon
      }
      cta {
        buttonText
        style
        action {
          __typename
          ... on ContentfulActionRedirect {
            __typename
            openInANewTab
            url
          }
        }
      }
      doctors {
        department
        name
        role
        slug
        details {
          json
        }
        picture {
          title
          fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
            src
          }
        }
      }
      headline: childContentfulClinicHeadlineRichTextNode {
        json
      }
      insurances: childContentfulClinicInsurancesRichTextNode {
        json
      }
      gallery {
        title
        file {
          url
        }
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      image {
        title
        file {
          url
        }
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sidebarDetails: childContentfulClinicSidebarDetailsRichTextNode {
        json
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }
    template: contentfulWebPageTemplate(model: { eq: "Clinic" }) {
      slugPrefix
      title
      sections {
        __typename
        ... on ContentfulSubheader {
          __typename
          backgroundColor
        }
        ... on ContentfulTemplateSection {
          __typename
          title
          additionalSettings {
            enableDirectionsCTA
          }
        }
      }
    }
  }
`
